<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-spinner />
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import store from '@/store'
import RoleTypes from '@/enums/RoleTypes'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import SessionService from '@/services/sessionService'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BSpinner,
  },
  setup() {
    const user = computed(() => store.getters['session/user'])

    if (user.value.role != null) {
      switch (user.value.role.get()) {
        case RoleTypes.Admin.get():
          router.push({ name: 'users' })
          break
        case RoleTypes.Customer.get():
          router.push({ name: 'orders' })
          break
        case RoleTypes.Worker.get():
          router.push({ name: 'fulfillment' })
          break
        default:
          SessionService.logout()
          break
      }
    } else {
      SessionService.logout()
    }
  },
}
</script>

<style>

</style>
